<template>
  <form @submit.prevent="save()">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="name">Name</label>
          <input
            type="text"
            class="form-control"
            name="name"
            v-model.trim="form.name"
            required
          />
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-dark" :disabled="submitting">
      Speichern
    </button>
    <div class="alert alert-danger mt-3" v-if="errMsg">{{ errMsg }}</div>
  </form>
</template>

<script>
import { firestore } from '../firebase';

export default {
  data() {
    return {
      holding: null,
      form: {
        name: ''
      },
      submitting: false,
      errMsg: ''
    };
  },
  async created() {
    const id = this.$route.params.id;

    if (id) {
      const holdingSnapshot = await firestore
        .collection('holdings')
        .doc(id)
        .get();

      this.holding = { id: holdingSnapshot.id, ...holdingSnapshot.data() };
      this.form = { ...this.holding };

      delete this.form.id;
    }
  },
  methods: {
    async save() {
      this.submitting = true;
      this.errMsg = '';

      try {
        if (this.holding) {
          await firestore
            .collection('holdings')
            .doc(this.holding.id)
            .update({
              ...this.form
            });
        } else {
          await firestore.collection('holdings').add({
            ...this.form
          });
        }

        this.$router.push({
          name: 'holdings'
        });
      } catch (err) {
        this.errMsg = err.message;

        throw err;
      } finally {
        this.submitting = false;
      }
    }
  }
};
</script>
